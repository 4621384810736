import images from './images';

const Menu = [
    {
        text: 'About us',
        link: '#team',
    },

    {
        text: 'Products',
        link: '#products',
    },
    {
        text: 'Use Cases',
        link: '#use-cases',
    },
    {
        text: 'Services',
        link: '#services',
    },
    {
        text: 'Contact Us',
        link: '#quote',
    }
];
const ServicesData = [
    {
        titleone: 'Data Frameworks',
        titletwo: 'data-platforms',
        link: '#',
        itemclass: 'blight',
        imgURL: images.services01,
    },
    {
        titleone: 'AI Frameworks',
        titletwo: 'ai-platforms',
        link: '#',
        itemclass: 'bgreen',
        imgURL: images.services02,
    },
    {
        titleone: 'GenAI Frameworks',
        titletwo: 'genai-platforms',
        link: '#',
        itemclass: 'bgreen',
        imgURL: images.services02,
    },
    {
        titleone: 'Sensory Frameworks',
        titletwo: 'sense-platforms',
        link: '#',
        itemclass: 'bdark',
        imgURL: images.services03,
    },
    {
        titleone: 'Analytics',
        titletwo: 'analytics',
        link: '#',
        itemclass: 'bdark',
        imgURL: images.services06,
    },
]
const CaseStudies = [
    {
        text: 'GenAI frameworks from embedding to benchmark analysis of LLM models',
        link: '#',
    }
];

const WorkingProcess = [
    {
        title: 'Problem-Solution analysis',
        description: 'Explore the real world to understand the problems and possible solutions and analyse the applicability of AI, GenAI',
    },
    {
        title: 'Research and Strategy Development',
        description: 'Problem definition, solution research, existing solutions, possible pitfalls, and strategy preparation by identifying paradigms, frameworks',
    },
    {
        title: 'Design and Architecture',
        description: 'Design and architect the solution using the technologies, tools, methodologies, paradigms identified as part of strategy',
    },
    {
        title: 'Implementation',
        description: 'Implement the AI, GenAI Models',
    },
    {
        title: 'Security and Ethics',
        description: 'Run through the security tests and validate the ethical standards',
    },
    {
        title: 'Monitoring and Optimization',
        description: 'Continuous monitoring, alerts, usage optimization, cache management, session optimization, scaling',
    },
    {
        title: 'Benchmark evaluation',
        description: 'Benchmark evaluate prod ready models and document the metrics',
    },
    {
        title: 'Continual Improvement',
        description: 'Version the models, document the improvements',
    },

];

const Team = [
    {
        name: 'John Smith',
        position: 'CEO and Founder',
        info: '10+ years of experience in digital marketing. Expertise in SEO, PPC, and content strategy',
        foto: images.team01,
        linkedin: '#',
    },
    {
        name: 'Jane Doe',
        position: 'Director of Operations',
        info: '7+ years of experience in project management and team leadership. Strong organizational and communication skills',
        foto: images.team02,
        linkedin: '#',
    },
    {
        name: 'Michael Brown',
        position: 'Senior SEO Specialist',
        info: '5+ years of experience in SEO and content creation. Proficient in keyword research and on-page optimization',
        foto: images.team03,
        linkedin: '#',
    },
    {
        name: 'Emily Johnson',
        position: 'PPC Manager',
        info: '3+ years of experience in paid search advertising. Skilled in campaign management and performance analysis',
        foto: images.team04,
        linkedin: '#',
    },
    {
        name: 'Brian Williams',
        position: 'Social Media Specialist',
        info: '4+ years of experience in social media marketing. Proficient in creating and scheduling content, analyzing metrics, and building engagement',
        foto: images.team05,
        linkedin: '#',
    },
    {
        name: 'Sarah Kim',
        position: 'Content Creator',
        info: '2+ years of experience in writing and editing Skilled in creating compelling, SEO-optimized content for various industries',
        foto: images.team06,
        linkedin: '#',
    },

];

const Testimonials = [
    {
        name: 'John Smith',
        position: 'Marketing Director at XYZ Corp',
        testimonial: '"We have been working with Positivus for the past year and have seen a significant increase in website traffic and leads as a result of their efforts. The team is professional, responsive, and truly cares about the success of our business. We highly recommend Positivus to any company looking to grow their online presence."',
    },
    {
        name: 'John Smith',
        position: 'Marketing Director at XYZ Corp',
        testimonial: '"We have been working with Positivus for the past year and have seen a significant increase in website traffic and leads as a result of their efforts. The team is professional, responsive, and truly cares about the success of our business. We highly recommend Positivus to any company looking to grow their online presence."',
    },
    {
        name: 'John Smith',
        position: 'Marketing Director at XYZ Corp',
        testimonial: '"We have been working with Positivus for the past year and have seen a significant increase in website traffic and leads as a result of their efforts. The team is professional, responsive, and truly cares about the success of our business. We highly recommend Positivus to any company looking to grow their online presence."',
    },
    {
        name: 'John Smith',
        position: 'Marketing Director at XYZ Corp',
        testimonial: '"We have been working with Positivus for the past year and have seen a significant increase in website traffic and leads as a result of their efforts. The team is professional, responsive, and truly cares about the success of our business. We highly recommend Positivus to any company looking to grow their online presence."',
    },
];
export default { Menu, CaseStudies, WorkingProcess, Team, Testimonials, ServicesData };